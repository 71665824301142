export const CONTENT_TYPES = {
    SINGLE_ASSET: 'DigitalSingleAssetRecord', //TODO: Uses lib component
    ASSET_WITH_HEADER: 'DigitalAssetWithHeaderAndDescriptionRecord', //TODO: Uses lib component
    HEADER_AND_DESCRIPTION: 'DigitalHeaderAndDescriptionRecord', //TODO: Uses lib component
    ASSETS_GALLERY: 'DigitalAssetsGalleryRecord', //TODO: Uses lib component
    PROJECT_SECTION_HEADER: 'DigitalProjectSectionHeaderRecord', //TODO: Not used
    PROJECT_RANGE: 'DigitalProjectRangeRecord', //TODO: Uses lib component
    PINNED_TEXT_WITH_IMAGE: 'DigitalPinnedTextWithImageRecord', //TODO: Uses lib component
    TECHNOLOGIES_TABLE: 'DigitalTechnologiesTableRecord', //TODO: Uses lib component
    ABOUT_TEAM: 'DigitalAboutTeamModuleRecord',
    FAQ: 'DigitalFaqRecord', //TODO: Uses lib component
    SOCIAL_MEDIA: 'DigitalSocialMediaModuleRecord', //TODO: Uses lib component
    ALL_PROJECTS_PAGE: 'DigitalAllProjectsPageRecord',
    SINGLE_PAGE: 'DigitalSinglePageRecord',
    SINGLE_PROJECT: 'DigitalSingleProjectRecord',
    SINGLE_DIGITAL_PROJECT: 'DigitalSingleProjectRecord',
    SINGLE_DESIGN_PROJECT: 'DesignSingleProjectRecord',
    SINGLE_HOW_WE_WORK_PAGE: 'DigitalSingleHowWeWorkPageRecord',
    SINGLE_HOW_WE_WORK_PAGE_NEW: 'CommonHowWeWorkPageRecord',
    MARGIN: 'MarginRecord',
    ALL_NEWS_PRESENTATION: 'GroupAllNewsPresentationRecord',
    NEWS_THUMBNAILS: 'NewsThumbnailRecord',
}

export const PADDING = {
    EXTRA_SMALL: 'extra-small',
    SMALL: 'small',
    MEDIUM: 'medium',
    LARGE: 'large',
    EXTRA_LARGE: 'extra-large',
    NONE: 'none'
}
